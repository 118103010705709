import Swiper from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';

const swiper = new Swiper('.swiper', {
  modules: [Pagination, Navigation],
  loop: true,
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

document.addEventListener('DOMContentLoaded', () => {
  const burger = document.querySelector('.burger__wrapper');
  const burgerLines = burger.querySelector('.burger');
  const navbar = document.querySelector('.navbar');
  const overlayNavbar = document.querySelector('.navbar__overlay');
  const body = document.querySelector('body');


  let navbarOpened = false

  const openNavbar = () => {
    burgerLines.classList.add('burger--active');
    navbar.classList.add('navbar--active');
    overlayNavbar.classList.add('navbar__overlay--active');
    body.classList.add('page__body--overflow');
    navbarOpened = true;
  }

  const closeNavbar = () => {
    burgerLines.classList.remove('burger--active');
    navbar.classList.remove('navbar--active');
    overlayNavbar.classList.remove('navbar__overlay--active');
    body.classList.remove('page__body--overflow');
    navbarOpened = false;
  }

  burger.addEventListener('click', () => {
    if (navbarOpened) {
      closeNavbar()
    } else {
      openNavbar()
    }

    if (window.innerWidth < 768) {
      bodyScrollToggle()
    }
  })

  overlayNavbar.addEventListener('click', closeNavbar)

  let swipeStart = 0
  overlayNavbar.addEventListener("touchstart", (e) => {
    // closeNavbar()
    swipeStart = e.targetTouches[0].pageY;
  });

  overlayNavbar.addEventListener("touchmove", (e) => {
    const swipeEnd = e.changedTouches[0].pageY;
    if (Math.abs(swipeEnd - swipeStart) > 40) {
      closeNavbar()
    }
  })

  overlayNavbar.addEventListener("touchend", (e) => {
    swipeStart = e.changedTouches[0].pageY;
  });
})
